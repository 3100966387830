


































import { Component, Vue, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";

import { Api, ApiLogDTO, PaidApiLogDTO } from "@/api";
import { CallHistoryTable } from "@/components";
import { defaultApiHistoryParams } from "@/constants";
import { ApiHistoryView, Organization } from "@/types";

interface FormSelectOption {
  value: string;
  text: string;
}

@Component({
  components: {
    CallHistoryTable,
  },
})
export default class ApiHistory extends Vue {
  private logItems: PaidApiLogDTO[] | ApiLogDTO[] = [];
  private logOptions = [
    { value: false, text: "Subscription API History" },
    { value: true, text: "All API History (Audit Logs)" },
  ];
  private params = defaultApiHistoryParams;
  private totalCount = 0;

  private organizations: Organization[] = [];
  private organizationId: string | null = null;
  private organizationIdOptions: FormSelectOption[] = [];
  private showAuditLogs = false;
  private viewType = ApiHistoryView.ACCOUNT_PAID;

  private async mounted(): Promise<void> {
    this.getOrganizationIdOptions();
    await this.loadLogs();
  }

  private async getOrganizationIdOptions(): Promise<void> {
    this.organizations = await Api.getAllOrganizations();
    this.organizationIdOptions = this.organizations.map((org) => {
      return {
        value: org.id,
        text: `${org.name} (${org.id})`,
      };
    });
  }

  @Watch("organizationId")
  @Watch("showAuditLogs")
  private async setViewType(): Promise<void> {
    if (this.organizationId && this.showAuditLogs) {
      this.viewType = ApiHistoryView.ORGANIZATION_ALL;
    } else if (this.organizationId && !this.showAuditLogs) {
      this.viewType = ApiHistoryView.ORGANIZATION_PAID;
    } else if (!this.organizationId && this.showAuditLogs) {
      this.viewType = ApiHistoryView.ACCOUNT_ALL;
    } else {
      this.viewType = ApiHistoryView.ACCOUNT_PAID;
    }
    await this.loadLogs();
  }

  private async loadLogs(): Promise<void> {
    try {
      const resp = await this.getApiHistory();
      this.totalCount = this.getLogsCount(resp!);
      this.logItems = resp!.data;
    } catch (e) {
      console.error(e);
    }
  }

  private async getApiHistory(): Promise<AxiosResponse> {
    switch (this.viewType) {
      case ApiHistoryView.ACCOUNT_ALL:
        return await Api.getApiLog(this.params);
      case ApiHistoryView.ACCOUNT_PAID:
        return await Api.getApiLogPaying(this.params);
      case ApiHistoryView.ORGANIZATION_ALL:
        return await Api.getOrganizationApiLog(
          this.organizationId!,
          this.params
        );
      case ApiHistoryView.ORGANIZATION_PAID:
        return await Api.getOrganizationApiLogPaying(
          this.organizationId!,
          this.params
        );
      default:
        throw Error(`Unknown View Type with number ${this.viewType}`);
    }
  }

  private getLogsCount(resp: AxiosResponse): number {
    return parseInt(resp.headers["x-total-count"]);
  }
}
